$(function() {
  $("[type=checkbox][value=all]").each(function() {
    set_up_checkbox_all(this.name);
  });

  $("[data-datepicker]").datepicker({
    dateFormat: "yy-mm-dd",
    constrainInput: false,
  });
  const monthPickerOptions = {
    Button: false,
    MonthFormat: "yy年mm月",
    MaxMonth: "0",
    MinMonth: "2016年05月",
    i18n: {
      months: [...Array(12).keys()].map((i) => `${i + 1}月`),
    },
  };
  $('[data-monthpicker]').MonthPicker(monthPickerOptions);

  set_up_todo_course_params();
  set_up_course_params();
  set_up_course_params_csv();
  set_up_ranking_type();
  set_up_average_type();
  set_up_todo_due_date_period();
  if (!$("#todo_search_form_category").length) {
    set_up_score_type();
    set_up_category_params();
  }

  set_up_validator();
});

/***
 * function set_up_checkbox_all_param(name)
 *
 * Checkbox all is selected when all other checkbox selected.
 * Checkbox all is unselected when any other checkbox unselected.
 */
function set_up_checkbox_all(name) {
  const checkboxes = $(`[type=checkbox][name='${name}']`);
  const checkbox_all = checkboxes.filter("[value=all]")[0];
  const other_checkboxes = checkboxes.filter("[value!=all]");

  function reload_checkbox_all() {
    checkbox_all.checked =
      other_checkboxes.filter(":checked").length === other_checkboxes.length;
  }

  function set_other_checkboxes_value(value) {
    other_checkboxes.each(function() {
      this.checked = value;
    });
  }

  if (checkbox_all.checked) {
    set_other_checkboxes_value(true);
  }

  $(checkbox_all).change(function() {
    set_other_checkboxes_value(this.checked);
  });

  other_checkboxes.change(reload_checkbox_all);
}

/***
 * function set_up_todo_course_param()
 *
 * Implement select tag hierarchy (Area > Group > Course)
 */
function set_up_todo_course_params() {
  const area_el = $("select[name='todo_search_form[course_group_area_id]']");
  const group_el = $("select[name='todo_search_form[course_group_id]']");
  const course_el = $("select[name='todo_search_form[course_id]']");

  if (!course_el.length) {
    return;
  }

  function reload_params() {
    const area_id = area_el.val();
    set_select_options(group_el, group_options(area_id));

    const group_id = group_el.val();
    set_select_options(course_el, course_options(group_id, area_id));
  }

  function group_options(area_id) {
    if (area_id) {
      return gon.course_group_names_in_area[area_id];
    } else {
      return gon.course_group_names;
    }
  }

  function course_options(group_id, area_id) {
    if (group_id) {
      return gon.course_names_in_group[group_id];
    } else if (area_id) {
      return gon.course_names_in_area[area_id];
    } else {
      return gon.course_names;
    }
  }

  area_el.change(reload_params);
  group_el.change(reload_params);
  reload_params();
}

/***
 * function set_up_course_param()
 *
 * Implement select tag hierarchy (Area > Group)
 */
function set_up_course_params() {
  const owner_el = $("input[name='f[owner][]']");
  const area_el = $("input[name='f[area_id][]']");
  const group_el = $("select[name='f[group_id]']");
  const course_el = $("select[name='f[course_id]']");

  if (!group_el.length) {
    return;
  }

  function reload_params() {
    const area_id_list = $("input[name='f[area_id][]']:checked")
      .map((i, el) => el.value)
      .toArray();
    const group_id = group_el.val();
    const owner_list = ["all", "accordia_golf", "next_golf"];
    console.log(owner_list);

    set_select_options(group_el, group_options(area_id_list));
    set_select_options(
      course_el,
      course_options_for_owner(area_id_list, group_id, owner_list)
    );
  }

  function group_options(area_id_list) {
    if (area_id_list.includes("all")) {
      return gon.course_group_names;
    } else {
      return area_id_list.reduce(
        (acc, area_id) => acc.concat(gon.course_group_names_in_area[area_id]),
        []
      );
    }
  }

  function course_options(area_id_list, group_id) {
    if (group_id) {
      return gon.course_names_in_group[group_id];
    } else if (!area_id_list.includes("all")) {
      return area_id_list.reduce(
        (acc, area_id) => acc.concat(gon.course_names_in_area[area_id]),
        []
      );
    } else {
      return gon.course_names;
    }
  }

  function course_options_for_owner(area_id, group_id, owner_list) {
    if (owner_list.includes("all")) {
      return course_options(area_id, group_id);
    }
    const course_names_for_owner = owner_list.reduce(
      (acc, owner) => acc.concat(gon.course_names_for_owner[owner]),
      []
    );
    return course_options(area_id, group_id).filter((course_name) =>
      course_names_for_owner?.some(
        (course_name_for_owner) =>
          course_name_for_owner[0] === course_name[0] &&
          course_name_for_owner[1] === course_name[1]
      )
    );
  }

  owner_el.change(reload_params);
  area_el.change(reload_params);
  group_el.change(reload_params);
  reload_params();
}

function set_up_course_params_csv() {
  const area_el = $("select[name='course_group_area_id']");
  const group_el = $("select[name='course_group_id']");
  const course_el = $("select[name='course_id_csv']");

  function reload_params(isChangeAll) {
    const area_id = area_el.val();
    const group_id = group_el.val();
    if (isChangeAll) {
      set_select_options(group_el, group_options(area_id)[0]);
      set_select_options(course_el, group_options(area_id)[1]);
    } else {
      set_select_options(course_el, course_options(group_id));
    }
  }

  function group_options(area_id) {
    const options = area_id
      ? gon.course_group_names_in_area[area_id]
      : Object.values(gon.course_group_names_in_area).flat();

    const options2 = area_id
      ? gon.course_names_in_area[area_id]
      : Object.values(gon.course_names_in_area).flat();
    return [options, options2];
  }

  function course_options(group_id) {
    return group_id
      ? gon.course_names_in_group[group_id]
      : Object.values(gon.course_names_in_group).flat();
  }

  area_el.change(() => reload_params(true));
  group_el.change(() => reload_params(false));
}

// Utility for set_up_*_course_params()
function set_select_options(el, options) {
  const select = $(el);
  const current_value = select.val();
  select.html("");
  select.append(
    $("<option>")
      .val("")
      .text("全て")
  );

  if (!options || !options.length) return;

  options.forEach(([key, value]) =>
    select.append(
      $("<option>")
        .val(key)
        .text(value)
    )
  );
  if (options.some((option) => option[0] == current_value)) {
    select.val(current_value);
  }
}

/***
 * function set_up_average_type()
 *
 * Control visiblity of average type
 */
function set_up_average_type() {
  const average_type_el = $('[name="f[average_type]"]:radio');
  const date_el = $(".f_average_date");
  const compare_date_el = $(
    ".f_average_compare_date_former, .f_average_compare_date_latter"
  );

  function reload_average_type_date() {
    const average_type = average_type_el.filter(":checked").val();
    if (average_type === "date") {
      date_el.show();
      compare_date_el.hide();
    } else {
      date_el.hide();
      compare_date_el.show();
    }
  }

  reload_average_type_date();
  average_type_el.change(reload_average_type_date);
}

/***
 * function set_up_ranking_type()
 *
 * Control visiblity of ranking type
 */
function set_up_ranking_type() {
  const ranking_type_el = $('[name="f[ranking_type]"]:radio');
  const date_el = $(".f_ranking_date");
  const compare_date_el = $(
    ".f_ranking_compare_date_former, .f_ranking_compare_date_latter"
  );

  function reload_ranking_type_date() {
    const ranking_type = ranking_type_el.filter(":checked").val();
    if (ranking_type === "date") {
      date_el.show();
      compare_date_el.hide();
    } else {
      date_el.hide();
      compare_date_el.show();
    }
  }

  reload_ranking_type_date();
  ranking_type_el.change(reload_ranking_type_date);
}

/***
 * function set_up_score_type()
 *
 * Control visiblity of score type
 */
function set_up_score_type() {
  const type_el = $('[name="f[score_type]"]:radio');
  if (!type_el.length) {
    return;
  }
  const matched_name = [
    "category_group",
    "category",
    "ranking_category_score",
    "category_score",
    "average_period_for_category",
  ];
  const unmatched_name = [
    "review_score_type",
    "review_score",
    "average_period ",
  ];
  const matched_el = $(
    matched_name
      .reduce((acc, name) => acc.concat(`[class*='f_${name}']`), [])
      .join(",")
  );
  const unmatched_el = $(
    unmatched_name
      .reduce((acc, name) => acc.concat(`[class*='f_${name}']`), [])
      .join(",")
  );

  function reload_score_type() {
    const type_value = type_el.filter(":checked").val();
    if (type_value === "category") {
      matched_el.show();
      unmatched_el.hide();
    } else {
      matched_el.hide();
      unmatched_el.show();
    }
  }

  reload_score_type();
  type_el.change(reload_score_type);
}

/***
 * function set_up_todo_due_date_period()
 *
 * Control visiblity of ranking type
 */
function set_up_todo_due_date_period() {
  const type_el = $('[name="todo_search_form[due_date_period]"]:radio');
  const date_el = $(".todo_search_form_due_date");

  function reload() {
    const type = type_el.filter(":checked").val();
    type === "specific_date" ? date_el.show() : date_el.hide();
  }

  reload();
  type_el.change(reload);
}

/***
 * function set_up_category_param()
 *
 * Implement select tag hierarchy (Area > Group)
 */
function set_up_category_params() {
  const group_el = $("[name='f[category_group]']");
  const category_el = $("[name='f[category]']");

  if (!group_el.length) {
    return;
  }

  function reload_params() {
    const group_id = group_el.val();

    set_select_options(category_el, category_options(group_id));
  }

  function category_options(group_id) {
    if (group_id) {
      return gon.category_names_group_by_category_group_id[group_id];
    }
  }

  group_el.change(reload_params);
  reload_params();
}

// バリデーション設定。getFormErrorsメソッドを定義し、最後に使う。
function set_up_validator() {
  // バリデーション設定。getXxxxErrorsメソッドを定義し、最後に使う。
  function getFormErrors() {
    // 日付関連処理。publihsed_at_beginとpublished_at_endをハードコードしたほうがいいかも。
    function dateParamNames() {
      return $('.search-body input[data-datepicker="true"]')
        .toArray()
        .filter((el) => !$(el).closest('[style*="display: none;"]').length)
        .map((el) => el.name.split("[")[1].split("]")[0]);
    }

    function isDateParamVaild(name) {
      return /^\d{4}-\d{2}-\d{2}$/.test($(`[name="f[${name}]"]`).val());
    }

    function getDateErrors() {
      if (dateParamNames().length) {
        const errors = dateParamNames()
          .map((name) => {
            if (!isDateParamVaild(name)) {
              const paramText = $(`label[for="f_${name}"]`).text();
              return `「${paramText}」の選択を正しく行ってください。`;
            }
          })
          .filter((v) => v);
        if (errors.length) {
          return errors;
        }
        const begin = $(`input[name="f[published_at_begin]"]`).val();
        const end = $(`input[name="f[published_at_end]"]`).val();
        if (begin > end) {
          return ["「いつまで」は「いつから」よりも後に設定してください。"];
        }
      }
      return [];
    }

    // 月関連処理。transition_beginとtransition_endをハードコードしたほうがいいかも。
    function monthParamNames() {
      return $('.search-body input[data-monthpicker="true"]')
        .toArray()
        .filter((el) => !$(el).closest('[style*="display: none;"]').length)
        .map((el) => el.name.split("[")[1].split("]")[0]);
    }

    function isMonthParamVaild(name) {
      return /^\d{4}年\d{2}月$/.test($(`[name="f[${name}]"]`).val());
    }

    function getMonthErrors() {
      if (monthParamNames().length) {
        const errors = monthParamNames()
          .map((name) => {
            if (!isMonthParamVaild(name)) {
              const paramText = $(`label[for="f_${name}"]`).text();
              return `「${paramText}」の選択を正しく行ってください。`;
            }
          })
          .filter((v) => v);
        if (errors.length) {
          return errors;
        }
        const begin = $(`input[name="f[transition_begin]"]`).val();
        const end = $(`input[name="f[transition_end]"]`).val();
        if (begin > end) {
          return ["「いつまで」は「いつから」よりも後に設定してください。"];
        }
      }
      return [];
    }

    // チェックボックスを処理。*_allがあるものは、何かしらをセットしないといけない
    function listParamNames() {
      return $(".search-body .search-item.check_boxes")
        .find("input[id$=_all]")
        .toArray()
        .filter((el) => !$(el).closest('[style*="display: none;"]').length)
        .map((el) => el.name.split("[")[1].split("]")[0]);
    }

    function isListParamVaild(name) {
      if (name === "status1") return true;
      return $(`[type=checkbox][name="f[${name}][]"]`)
        .toArray()
        .some((el) => el.checked);
    }

    function getListErrors() {
      return listParamNames()
        .map((name) => {
          if (!isListParamVaild(name)) {
            const paramText = $(`[class*="f_${name}"] > label`).text();
            return `「${paramText}」の選択を正しく行ってください。`;
          }
        })
        .filter((v) => v);
    }

    // 各エラーを集計
    return getDateErrors()
      .concat(getMonthErrors())
      .concat(getListErrors());
  }

  // 送信前にチェックし、エラーがあればアラート
  $("form.simple_form[id$='_f']").on("submit", () => {
    const errors = getFormErrors();
    if (errors.length) {
      alert(errors.join("\n"));
      return false;
    }
    return true;
  });
}
